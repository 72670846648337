<script setup lang="ts">
import type { PropType, Prop } from "vue";
import type { Wishlist } from "../../types";

const props = {
  modelValue: Object as PropType<Wishlist>,
};
const emits = defineEmits(["update:modelValue"]);

const wishlistStore = useWishlistStore();
const { wishlist } = storeToRefs(wishlistStore);
const model = computed({
  get: () => props.modelValue,
  set: (x) => emits("update:modelValue", x),
});

const selected = ref({} as Wishlist);
const selected_wishlist = computed({
  get: () => selected.value,
  set: async (x: any) => {
    if (x.id) {
      // // console.log(x)
      selected.value = x;
      return x;
    }

    const _wish = await wishlistStore.create({ name: x.name });
    if (_wish) {
      selected.value = _wish;
      return _wish;
      // emits('update:modelValue', _wish)
    }
  },
});
watch(selected, () => {
  model.value = selected.value as any;
});
</script>

<template>
  <USelectMenu v-model="selected_wishlist" :options="wishlist" option-attribute="name" searchable creatable>
    <template #label>
      <template v-if="selected_wishlist.id">
        <span class="truncate">{{ selected_wishlist.name }}</span>
      </template>

      <template v-else>
        <span class="truncate">Select wishlist</span>
      </template>
    </template>
  </USelectMenu>
</template>
