<script setup lang="ts">
const props = defineProps({
  color: { type: String, default: "text-gray-950 dark:text-white" },
  hover: {
    type: String,
    default:
      "text-rose-500 dark:text-rose-400 fill-rose-500 dark:fill-rose-400",
  },

  name: { type: String, default: "i-ph:heart" },
  likedName: { type: String, default: "i-ph:heart-fill" },
  liked: Boolean,
  position: { type: String, default: "top-2 right-2" },
  base: {
    type: String,
    default:
      "bg-gray-200 dark:bg-gray-800 rounded-full w-fit h-fit p-2 flex items-center cursor-pointer",
  },
  strategy: { type: String, default: "absolute" },
  size: { type: String, default: "w-5 h-5" },
});

const hearts = computed(() => {
  return [
    { name: props.name, style: props.color, show: !props.liked },
    { name: props.likedName, style: props.hover, show: props.liked },
  ];
});
</script>

<template>
  <template v-for="heart of hearts">
    <span v-if="heart.show" :class="[position, base, strategy]">
      <UIcon
        dynamic
        :name="heart.name"
        :class="[heart.style, size]"
        v-bind="$attrs"
      />
    </span>
  </template>
</template>
