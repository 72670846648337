<script setup lang="ts">
import { usePricing } from "#imports";
import type { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import type { PropType } from "vue";
import type { Wishlist } from "../../../types";
import { upperCase } from 'lodash-es'
// stores
const { isLoggedIn } = storeToRefs(useCustomerStore());
const favouriteStore = useFavouriteStore();
const { all_products: fav_products } = storeToRefs(favouriteStore);
const regionStore = useRegionStore()
const { currencyCode } = storeToRefs(regionStore)

//ui for prop
type ui = {
  border: {
    size?: string;
    style?: string;
  };
};
// component props
const props = defineProps({
  product: {
    type: Object as PropType<PricedProduct>,
    required: true,
  },
  ui: {
    type: Object as PropType<ui>,
    default: {
      border: {
        size: "border",
        style: "border-gray-300 dark:border-gray-800 rounded-xl",
      },
    },
  },
});
const product = computed(() => props.product); // product in context
const show = ref(false); // variable determines which picture to show

// second and first picture for hovering effect
const second_picture = computed(() => {
  return product.value.images?.[1]?.url ?? product.value.images?.[0]?.url;
});
const first_picture = computed(() => {
  return product.value.images?.[0]?.url ?? product.value?.thumbnail;
});

// alternating pictures on hover
function mouseEnter() {
  show.value = true;
  showSpecial.value = true;
}

function mouseLeave() {
  show.value = false;
  showSpecial.value = false;
}

//calculating price
const price = computed(() => {
  // const _price = product.value.variants.map((x) => x.prices[0].amount);
  const pr_ = product.value.variants.flatMap(x => x.prices).filter(x => x.currency_code == currencyCode.value)
  // return Math.min(..._price);
  return usePricing(pr_[0].amount, pr_[0].currency_code)
});

// const currency_code = computed(() => {
//   const _cc = product.value.variants.map((x) => x.prices[0].currency_code);
//   return _cc[0];
// });

const showSpecial = ref(false); // for showing favourite, wishlist icon
const isFavourite = computed(() =>
  fav_products.value.includes(product.value.id as string),
);
const isWishlist = ref(false);

async function markFavourite() {
  const res = (await useCybandyClient().customMethods.favourite.likeDislike(
    product.value.id as string,
  )) as any;

  if (Object.keys(res).includes("deleted")) {
    favouriteStore.disliked(res.id);
  } else {
    favouriteStore.liked(res.product_id as any, res.id);
  }
}
async function markWishList() {
  addToWishlist.value = true;
  isWishlist.value = true;
  setTimeout(() => {
    isWishlist.value = false;
  }, 1000);
}

const addToWishlist = ref(false);
const wishlist_id = ref({} as Wishlist);
async function addToWishlistFunction() {
  if (wishlist_id.value) {
    try {
      await useCybandyClient().customMethods.wishlist.addProducts(
        wishlist_id.value.id,
        [product.value.id as string],
      );
    } catch (error) { }
  }
  addToWishlist.value = false;
}

// seo 
// useSchemaOrg([
//   defineProduct({
//     name: upperCase(product.value.title),
//     description: product.value.description,
//     image: product.value.images?.map(x => x.url),
//     offers: [
//       { price: price.value }
//     ]
//   })
// ])

defineImage({
  url: product.value.thumbnail,
  caption: product.value.title
})
</script>

<template>
  <div class="h-full">
    <div
      class="space-y-4 shadow hover:shadow-lg dark:shadow-gray-900 max-w-full h-fit bg-primary-50/80 dark:bg-gray-900"
      :class="[ui.border.size, ui.border.style]">
      <TransitionGroup name="fade" tag="div">
        <div :key="product.id" @mouseenter="mouseEnter" @mouseleave="mouseLeave" class="relative">
          <div v-if="isLoggedIn && showSpecial" class="absolute top-1 right-1 space-y-1" @mouseenter="mouseEnter"
            @mouseleave="mouseLeave">
            <!-- <TransitionGroup :duration="300"> -->
            <CyUiFavourite :key="Date.now() + Math.random()" :liked="isFavourite" @click="markFavourite"
              strategy="relative" />
            <CyUiBookmark :key="Date.now() + Math.random()" :liked="isWishlist" @click="markWishList"
              strategy="relative" />
            <!-- </TransitionGroup> -->
          </div>

          <NuxtLink @mouseover="mouseEnter" @mouseleave="mouseLeave" :to="`/shop/jewels/${product.handle}`">
            <LazyNuxtImg v-show="!show" :src="first_picture || ''" format="webp" placeholder="/placeholder_bazari.png"
              provider="weserv" preset="prod_listing" class="w-full aspect-square prod_img" :alt="product.title"
              :title="product.title" />
            <LazyNuxtImg v-show="show" :src="second_picture || ''" format="webp" placeholder="/placeholder_bazari.png"
              provider="weserv" preset="prod_listing" class="w-full aspect-square prod_img" :alt="product.title"
              :title="product.title" />
          </NuxtLink>
        </div>
      </TransitionGroup>

      <div class="flex items-center justify-between px-4 py-2">
        <div class="flex flex-col gap-2 justify-start">
          <NuxtLink :to="`/shop/jewels/${product.handle}`" class="uppercase font-medium">
            {{ product.title }}
          </NuxtLink>
          <span v-if="isLoggedIn" class="text-start">{{ price }}</span>
        </div>

        <CyUiIconsShoppingBag v-if="isLoggedIn" />
      </div>
    </div>

    <ModalTitleButton @send="addToWishlistFunction" v-model="addToWishlist" title="Select Wishlist"
      description="Select the wishlist to add product" button-confirm-label="Add">
      <div class="min-h-[150px]">
        <WishlistSelectWishlist v-model="wishlist_id" />
      </div>
    </ModalTitleButton>
  </div>
</template>

<style scoped>
.prod-img {
  aspect-ratio: 1/1;
}

.fade-enter-active {
  transition: all 0.4s ease-out;
}

.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateX();
  opacity: 0;
}
</style>
